interface NoDataPlaceholderProps {
  text: string | React.ReactNode;
  footer?: React.ReactNode;
}

const NoDataPlaceholder: React.FC<NoDataPlaceholderProps> = ({ text, footer = <></> }) => {
  return (
    <div className="mx-4 mb-ds-xl mt-ds-xl flex h-60 flex-col justify-center rounded-xl bg-color-bg-lightmode-secondary px-4 text-center dark:bg-color-bg-darkmode-secondary">
      <span className="typography-product-text-card text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
        {text}
      </span>
      {footer}
    </div>
  );
};

export default NoDataPlaceholder;
